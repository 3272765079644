export default function ({ $auth, route, redirect, next }) {
  if ($auth.loggedIn) {
    const { username } = $auth.user;
    const routename = route.name.split('___')[0];
    
    if (!username && routename != 'home-signup') {
      return redirect('/home/signup')
    }

    if (username && routename == 'home-signup') {
      return redirect('/home')
    }
  }

  // next(route.path);
}
