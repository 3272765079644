const lang = {
  "insight": "洞察",
  "promo_code": {
    "title": "優惠碼",
    "button": {
      "create": "新增優惠碼",
      'share': '分享優惠碼'
    },
    "used_times": "已使用次數",
    'limit': '使用次數',
    'period': '期限',
    'min_spending': '最低消費',
    'with_campaigns': '活動',
    'apply': '適用於',
    'status': '狀態',
    'copied': '優惠碼已複製，貼上至你的朋友聊天室'
  },
  'no-record': '沒有記錄',
  'possing': '處理中',
  'keywords': '關鍵字',
  'button': {
    'copy': '複製',
    'edit': '編輯',
    'delete': '刪除',
    'cancel': '取消',
    'submit': '確認',
    'search': '搜尋',
    'reset': '重設',
    'create': '新增',
    'all_in_one_voucher': '一鍵"多買優惠"',
    'create_product': '新增產品',
  },
  'ushop': {
    'title': {
      'order-or-bussinesshour': '訂單/營業時間',
      'order-rule': '訂單規則',
      'variation-option': '產品選項',
      'product': '產品',
      'product-category': '產品分類',
    },
    'label': {
      'daily_order_start_at': '每日訂單開始時間',
      'daily_order_end_at': '每日訂單結束時間',
      'daily_order_timestamp': '時段間隔(以分鐘計算，最小為5分鐘，最大為360分鐘)'
    },
    'order': {
      'no': '單號',
      'status': {
        'cancel': '已取消及退款',
        'pickup': '已取貨在',
      },
      'detail': {
        'tel': '聯絡',
        'type': '取貨',
        note: '備註',
      },
      'item': {
        'quantity': '數量',
      },
      button: {
        'cancel': '取消',
        'pickup': '取貨',
        'notify': '發送通知',
        'notified': '已成功發送通知',
      },
      message: {
        'config_notify': '確定發送通知？',
      }
    },
    'button': {
      'save': '儲存',
      'cancel': '取消',
      'submit': '確認',
      'search': '搜尋',
      'reset': '重設',
      'create': '新增',
      'edit': '編輯',
      'use': '使用',
      'delete': '刪除',
      'pin': '置頂',
      'unpin': '取消置頂',
      'copy': '複製',
      'sort_top': '移到最前',
      'sort_bottom': '移到最後',
      'create_variation_option': '新增選項',
    },
    'message': {
      'copied': '已複製',
    }
  },
  index: {
    console: '我的控制台',
  },
  home: {
    title: {
      secret: '匿名投稿頁',
    },
    button: {
      meta: '建立從Facebook',
      create_secret: '建立匿名投稿頁',
      create_secret_now: '立即建立匿名投稿頁'
    },
    record: {
      no_secret: '沒有匿名投稿頁，'
    }
  },
  nav: {
    category: '分類',
    product: '產品',
    order: '訂單',
    product_variation: '選項樣式',
    poster: '海報',
  }
}


// export default lang;
module.exports = lang;